import React from 'react';
import { graphql } from "gatsby";
import * as b from "../components/bootstrap.module.css";
import BlogContent from "../components/blog-content";
import Breadcrumbs from "../components/breadcrumbs"
import Posts from '../components/posts';
import Layout from '../components/layout';

import BaseHead from '../components/base-head';
import siteMetadata from "../utils/site-metadata";

export const Head = ({...props}) => (
  <BaseHead {...props}>
    {props?.pageContext?.mysqlId && <meta property="og:image" content={`${siteMetadata.domain}/social-media/term/${props.pageContext.mysqlId}.png`} /> }
  </BaseHead>
)

const ProductsPromoList = ({
  data: { mysqlTerms, allMysqlBlog }, 
  pageContext: { breadcrumbs },
  location: { pathname },
}) => {
  return (
    <Layout pathname={pathname}>
      <div className={[b.container, b.pt3,'breadcrumbs-mobile',b.ptLg5,"scroll-inner"].join(" ")}>
        <Breadcrumbs theme='white' items={breadcrumbs} />
      </div>
      <div className={[b.container, b.pbLg5, b.pt3].join(" ")}>
        <div className={[b.row,b.justifyContentBetween].join(' ')}>
          <div className={[b.colLg9,b.mbLg5,b.mb3].join(' ')}>
            <h1 className={["blackToWhite",'big-title',b.mt4, b.mb3].join(' ')}>{mysqlTerms.title}</h1>

            <div className={['blackToWhite', 'font-size-20',b.fontWeightNormal].join(' ')}>
              {allMysqlBlog.nodes.length > 0 ? (<>
                <p className={[b.mb3].join(' ')}>
                  Poniżej zebraliśmy wszystkie najlepsze, aktualne <b>{mysqlTerms.title}</b> proponowane przez banki w Polsce. Sprawdź jakie nagrody można otrzymać w ramach promocji i jakie są warunki ich otrzymania. W opisach znajdziesz instrukcję wraz z najważniejszymi informacjami, jak skorzystać z promocyjnej oferty.
                </p>
                <p className={[b.mb3].join(' ')}>
                  Zapoznaj się z promocjami poniżej, wybierz te, które Cię interesują i zapoznaj się ze szczegółami ofert. Skorzystaj już teraz! Większość ofert jest ograniczona czasowo! 
                </p>
              </>) : (<>
                <p className={[b.mb3].join(' ')}>
                  Niestety! Aktualnie <b>{mysqlTerms.title}</b> nie są dostępne w żadnym banku! Jeśli szukasz najlepszej oferty skorzystaj z naszej porównywarki i sprawdź, który bank zapewnia najlepsze warunki bez promocji.
                </p>
                <p className={[b.mb3].join(' ')}>
                  Codziennie sprawdzamy oferty banków i aktualizujemy <b>{mysqlTerms.title}</b> na bieżąco ! Informacje o każdej promocji wraz z instrukcją jak uzyskać bonus znajdziesz właśnie tutaj! Sprawdzaj Akredo regularnie!
                </p>
              </>)}
            </div>
          </div>
        </div>

        {allMysqlBlog.nodes.length > 0 && (<>
          <h2 className={b.mb4}>Wszystkie {mysqlTerms.term}</h2>
          <Posts items={allMysqlBlog.nodes} bankpromo/>
        </>)}
        
        <div className={["blog-content", b.row, b.mtLg3,'blueToGray', 'dark-li'].join(" ")}>
          <div className={[ b.colXl8, 'dark-theme-listing'].join(' ')}>
          {mysqlTerms.description && (
            <BlogContent content={mysqlTerms.description} className={[b.pr1, b.mb4].join(" ")}/>
          )}
          {mysqlTerms.description_alt && (
            <BlogContent content={mysqlTerms.description_alt} className={[b.pr1, b.mb4].join(" ")}/>
          )}
          {mysqlTerms.description_additional && (
            <BlogContent content={mysqlTerms.description_additional} className={[b.pr1, b.mb4].join(" ")}/>
          )}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default ProductsPromoList;

export const query = graphql`
query ProductPromoQuery($mysqlId: Int!) {
    mysqlTerms(mysqlId: {eq: $mysqlId}) {
      term_normalized
      title
      term
      mysqlId
    }
    allMysqlBlog(filter: {is_promo_end: { ne: 1 }, blogTerms: {elemMatch: {term_id: {eq: $mysqlId}}}}) {
      nodes {
        title
        image_description
        description
        url
        term_normalized
        terms_path
        mysqlImages {
          childImageSharp {
            gatsbyImageData(width: 70)
          }
        }
      }
    }
    allMysqlSeo(filter: { item_id: { eq: $mysqlId }, item_type: { eq: "term" }}) {
      nodes {
        ...SeoData
      }
    }
  }
`
